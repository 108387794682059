/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { bool, object } from 'prop-types';
import { getActiveABTests } from '../../../../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';

const styles = (theme) => ({
    rating: {
        fontSize: '1em',
        color: theme.palette.cms?.black || '#000000',
        '& img': {
            '@media only screen and (max-width: 768px)': {
                width: '14px',
            },
        },
    },
    abTestRating: {
        fontSize: 14,
        color: '#4E9F82',
        '& img': {
            '@media only screen and (max-width: 768px)': {
                width: '13px',
            },
        },
    },
    abTestRatingHalf: {
        display: 'inline-flex',
        fontSize: 14,
        marginRight: '-8px',
        '& > div:first-child': {
            overflow: 'hidden',
            width: '35%',
            zIndex: 1,
            color: '#4E9F82',
        },
        '& > div:last-child': {
            position: 'relative',
            marginLeft: '-7px',
        },
    },
    half: {
        display: 'inline-flex',
        fontSize: 16,
        marginRight: '-8px',
        '& > div:first-child': {
            overflow: 'hidden',
            width: '35%',
            zIndex: 1,
            color: theme.palette.cms?.black || '#000000',
        },
        '& > div:last-child': {
            position: 'relative',
            marginLeft: '-8px',
        },
    },
    starReviews: {
        fontSize: '0.8em',
        marginLeft: 5,
        color: theme.palette.yotpoRatingColor || theme.palette.bgTile2 || '#304562',
    },
    abTestStarReviews: {
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.01em',
        color: '#595959',
        marginLeft: '6px',
    },
    // abTestMarginBottom: {
    //     marginBottom: '6px',
    // },
    starSize: {
        fontSize: '1rem',
    },
    reviewContainer: {
        fontSize: '16px',
        lineHeight: '24px',
        [theme.breakpoints.down(600)]: {
            '& $starReviews': {
                fontSize: '12px',
                lineHeight: '18px',
                fontWeight: 'normal',
            },
        },
    },
});

const RatingsUI = ({
    classes,
    reviews, collectionRestructureEnabled, isPdpRedesignEnabled, isPowerReviewEnabled,
}) => {
    if (!reviews?.average_score) return null;
    const activeABTests = useSelector(getActiveABTests);
    const reviewCount = reviews?.total_reviews;
    const plural = () => {
        if (reviewCount !== 1) return 's';
        return '';
    };
    const stars = [];
    const rating = (Math.round(parseFloat(reviews?.average_score) * 2) / 2);

    const fullStars = isPowerReviewEnabled ? <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxOCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05IDBDOS4yMzg5OSAwIDkuNDU3MjYgMC4xMzQyNTUgOS41NjMwNSAwLjM0NjMyM0wxMi4wMDQxIDUuMjM5ODhMMTcuNDYyOSA2LjAyOTQ0QzE3LjY5OTMgNi4wNjM2NCAxNy44OTU3IDYuMjI3NjcgMTcuOTY5NCA2LjQ1MjYxQzE4LjA0MzEgNi42Nzc1NCAxNy45ODE0IDYuOTI0MzcgMTcuODEwMiA3LjA4OTM1TDEzLjg2MDcgMTAuODk1OUwxNC43OTI4IDE2LjI3MzZDMTQuODMzMiAxNi41MDY3IDE0LjczNjQgMTYuNzQyMyAxNC41NDMgMTYuODgxNEMxNC4zNDk2IDE3LjAyMDQgMTQuMDkzMiAxNy4wMzg3IDEzLjg4MTcgMTYuOTI4Nkw5IDE0LjM4ODJMNC4xMTgzMiAxNi45Mjg2QzMuOTA2NzcgMTcuMDM4NyAzLjY1MDQgMTcuMDIwNCAzLjQ1NzAyIDE2Ljg4MTRDMy4yNjM2MyAxNi43NDIzIDMuMTY2NzggMTYuNTA2NyAzLjIwNzE5IDE2LjI3MzZMNC4xMzkyNiAxMC44OTU5TDAuMTg5ODE0IDcuMDg5MzVDMC4wMTg2MzUxIDYuOTI0MzcgLTAuMDQzMDcwOSA2LjY3NzU0IDAuMDMwNjMxMSA2LjQ1MjYxQzAuMTA0MzMzIDYuMjI3NjcgMC4zMDA2NjcgNi4wNjM2NCAwLjUzNzExMSA2LjAyOTQ0TDUuOTk1OTMgNS4yMzk4OEw4LjQzNjk1IDAuMzQ2MzIzQzguNTQyNzQgMC4xMzQyNTUgOC43NjEwMSAwIDkgMFoiIGZpbGw9IiMyMjIyMjIiLz4KPC9zdmc+Cg==" alt="selected_star" width="15px" height="13px" /> : '★';
    const emptyStars = isPowerReviewEnabled ? <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05IDAuMjQyMTg4QzkuMjM4OTkgMC4yNDIxODggOS40NTcyNiAwLjM3NjQ0MyA5LjU2MzA1IDAuNTg4NTFMMTIuMDA0MSA1LjQ4MjA3TDE3LjQ2MjkgNi4yNzE2MkMxNy42OTkzIDYuMzA1ODIgMTcuODk1NyA2LjQ2OTg2IDE3Ljk2OTQgNi42OTQ3OUMxOC4wNDMxIDYuOTE5NzMgMTcuOTgxNCA3LjE2NjU2IDE3LjgxMDIgNy4zMzE1NEwxMy44NjA3IDExLjEzODFMMTQuNzkyOCAxNi41MTU4QzE0LjgzMzIgMTYuNzQ4OSAxNC43MzY0IDE2Ljk4NDUgMTQuNTQzIDE3LjEyMzVDMTQuMzQ5NiAxNy4yNjI2IDE0LjA5MzIgMTcuMjgwOSAxMy44ODE3IDE3LjE3MDhMOSAxNC42MzA0TDQuMTE4MzIgMTcuMTcwOEMzLjkwNjc3IDE3LjI4MDkgMy42NTA0IDE3LjI2MjYgMy40NTcwMiAxNy4xMjM1QzMuMjYzNjMgMTYuOTg0NSAzLjE2Njc4IDE2Ljc0ODkgMy4yMDcxOSAxNi41MTU4TDQuMTM5MjYgMTEuMTM4MUwwLjE4OTgxNCA3LjMzMTU0QzAuMDE4NjM1MSA3LjE2NjU2IC0wLjA0MzA3MDkgNi45MTk3MyAwLjAzMDYzMTEgNi42OTQ3OUMwLjEwNDMzMyA2LjQ2OTg2IDAuMzAwNjY3IDYuMzA1ODIgMC41MzcxMTEgNi4yNzE2Mkw1Ljk5NTkzIDUuNDgyMDdMOC40MzY5NSAwLjU4ODUxQzguNTQyNzQgMC4zNzY0NDMgOC43NjEwMSAwLjI0MjE4OCA5IDAuMjQyMTg4Wk05IDIuMjY3MzFMNi45NzYwOCA2LjMyNDcxQzYuODg0NjkgNi41MDc5MiA2LjcwODEzIDYuNjM0OTYgNi41MDM4NCA2LjY2NDVMMS45NzY5MiA3LjMxOTI3TDUuMjUyMDcgMTAuNDc1OUM1LjQwMDE4IDEwLjYxODcgNS40Njc3OCAxMC44MjQ0IDUuNDMyODMgMTEuMDI2MUw0LjY2MDAzIDE1LjQ4NDhMOC43MDc3NCAxMy4zNzg0QzguODkwNyAxMy4yODMyIDkuMTA5MyAxMy4yODMyIDkuMjkyMjYgMTMuMzc4NEwxMy4zNCAxNS40ODQ4TDEyLjU2NzIgMTEuMDI2MUMxMi41MzIyIDEwLjgyNDQgMTIuNTk5OCAxMC42MTg3IDEyLjc0NzkgMTAuNDc1OUwxNi4wMjMxIDcuMzE5MjdMMTEuNDk2MiA2LjY2NDVDMTEuMjkxOSA2LjYzNDk2IDExLjExNTMgNi41MDc5MiAxMS4wMjM5IDYuMzI0NzFMOSAyLjI2NzMxWiIgZmlsbD0iIzIyMjIyMiIvPgo8L3N2Zz4K" alt="selected_star" width="15px" height="13px" /> : '☆';

    const selectedIcon = collectionRestructureEnabled ? <img src="https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blte9923b9932190441/6622897cb0ec77a696d6c00b/star-full.svg" alt="selected_star" width="15px" height="13px" /> : fullStars;
    const deselectedIcon = collectionRestructureEnabled ? <img src="https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt7b47c544e209dded/6622897cb05441576899bd13/star-empty.svg" alt="empty_star" width="15px" height="13px" /> : emptyStars;
    const halfSelectedIcon = collectionRestructureEnabled ? <img src="https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt528c6a1e6589e413/6622897c85518c27925559e5/star-half.svg" alt="half_star"  width="15px" height="13px" /> :  <span className={`${activeABTests?.mobile_simple_product_redesign ? classes.abTestRatingHalf : classes.half}`}><div>{fullStars}</div><div>{emptyStars}</div></span>;

    for (let i = 0; i < 5; i += 1) {
        stars.push(i);
    }
    const decideStarType = (star) => {
        let result = deselectedIcon;
        const isHalf = rating % 1 !== 0;
        if (rating > star) {
            result = selectedIcon;
        }
        if (isHalf && rating === (star + 0.5)) {
            result = halfSelectedIcon;
        }
        return result;
    };

    if (activeABTests?.mobile_simple_product_redesign) {
        return (
            <div className={isPdpRedesignEnabled ? classes.reviewContainer : ''}>
                <span className={classes.abTestRating}>
                    {stars.map((star) => (
                        <span>
                            {decideStarType(star)}
                        </span>
                    ))}
                </span>
                <span className={classes.abTestStarReviews}>{reviewCount} Review{plural()}</span>
            </div>
        );
    }

    return (
        <div className={isPdpRedesignEnabled ? classes.reviewContainer : ''}>
            <span className={classes.rating}>
                {stars.map((star) => (
                    <span className={classes.starSize}>
                        {decideStarType(star)}
                    </span>
                ))}
            </span>
            <span className={classes.starReviews}>{reviewCount} Review{plural()}</span>
        </div>
    );
};

RatingsUI.propTypes = {
    classes: object.isRequired,
    reviews: object,
    collectionRestructureEnabled: bool,
    isPdpRedesignEnabled: bool,
    isPowerReviewEnabled: bool,
};

RatingsUI.defaultProps = {
    reviews: {},
    collectionRestructureEnabled: false,
    isPdpRedesignEnabled: false,
    isPowerReviewEnabled: false,
};

export default withStyles(styles)(RatingsUI);
