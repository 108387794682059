/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { string } from 'prop-types';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#F7F1FF',
        padding: '4px 8px',
        borderRadius: '15px',
        width: 'max-content !important',
        marginBottom: '8px',
        [theme.breakpoints.down(600)]: {
            padding: '3px 8px',
        },
    },
    content: {
        fontFamily: 'Lato',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0.5px',
        [theme.breakpoints.down(600)]: {
            fontSize: '12px',
            lineHeight: '16px',
        },
    },
    icon: {
        marginRight: '6px',
        width: 'auto !important',
    },
}));
const DeliveryType = ({
    productDeliveryType,
}) => {
    const classes = useStyles();
    const getDeliveryType = () => {
        if (productDeliveryType?.toLowerCase() === 'gpt') {
            return true;
        }
        return false;
    };

    const iconGPT = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1658 8.39748C12.7672 8.39386 10.3948 8.89573 8.20311 9.87036C7.95079 9.98257 7.65529 9.86899 7.54308 9.61667C7.43087 9.36435 7.54446 9.06885 7.79677 8.95664C10.1168 7.92491 12.6282 7.39365 15.1673 7.39748C15.4435 7.3979 15.667 7.62209 15.6666 7.89824C15.6662 8.17438 15.442 8.3979 15.1658 8.39748Z" fill="#2F363D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1661 10.6025C13.6486 10.601 12.1397 10.831 10.6915 11.2846C10.428 11.3672 10.1475 11.2205 10.0649 10.957C9.98238 10.6934 10.1291 10.4129 10.3926 10.3304C11.9378 9.84633 13.5479 9.6009 15.1671 9.60255C15.4433 9.60283 15.6669 9.82691 15.6666 10.1031C15.6663 10.3792 15.4423 10.6028 15.1661 10.6025Z" fill="#2F363D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1665 12.8077C14.3854 12.8075 13.6059 12.8785 12.8378 13.02C12.5662 13.07 12.3055 12.8904 12.2555 12.6188C12.2055 12.3472 12.3851 12.0865 12.6567 12.0365C13.4847 11.884 14.3249 11.8074 15.1668 11.8077C15.443 11.8078 15.6667 12.0318 15.6667 12.3079C15.6666 12.5841 15.4426 12.8078 15.1665 12.8077Z" fill="#2F363D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.87251 14.9291C5.78763 13.5419 3.33837 12.8037 0.83413 12.8078C0.557988 12.8082 0.333765 12.5847 0.333314 12.3086C0.332862 12.0324 0.556354 11.8082 0.832496 11.8078C3.53441 11.8033 6.17702 12.5998 8.42648 14.0966C8.65638 14.2496 8.71874 14.56 8.56576 14.7899C8.41279 15.0198 8.10241 15.0821 7.87251 14.9291Z" fill="#2F363D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2859 14.8695C8.49734 12.13 4.74307 10.5974 0.833977 10.6026C0.557835 10.6029 0.33368 10.3794 0.333313 10.1032C0.332947 9.82707 0.556507 9.60292 0.832649 9.60255C5.00434 9.59701 9.01081 11.2326 11.9867 14.1561C12.1837 14.3496 12.1865 14.6662 11.993 14.8632C11.7995 15.0602 11.4829 15.063 11.2859 14.8695Z" fill="#2F363D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.2576 10.0842C5.94111 8.97148 3.40369 8.39495 0.833803 8.39747C0.557661 8.39774 0.333584 8.1741 0.333313 7.89796C0.333043 7.62182 0.556681 7.39774 0.832823 7.39747C3.55293 7.3948 6.23869 8.00503 8.69059 9.18283C11.1425 10.3606 13.2975 12.0757 14.9956 14.2007C15.168 14.4164 15.1328 14.7311 14.9171 14.9034C14.7014 15.0758 14.3868 15.0407 14.2144 14.825C12.6101 12.8173 10.5741 11.197 8.2576 10.0842Z" fill="#2F363D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.8025 1.43337C8.98028 1.30004 9.22473 1.30004 9.4025 1.43337L13.8127 4.74106C13.9387 4.83548 14.0127 4.98368 14.0127 5.14106V7.97395C14.0127 8.25009 13.7889 8.47395 13.5127 8.47395C13.2366 8.47395 13.0127 8.25009 13.0127 7.97395V5.39106L9.1025 2.45837L5.19226 5.39106V8.32056C5.19226 8.59671 4.9684 8.82056 4.69226 8.82056C4.41612 8.82056 4.19226 8.59671 4.19226 8.32056V5.14106C4.19226 4.98368 4.26636 4.83548 4.39226 4.74106L8.8025 1.43337Z" fill="#2F363D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.94867 6.24365C6.94867 5.96751 7.17253 5.74365 7.44867 5.74365H10.7564C11.0325 5.74365 11.2564 5.96751 11.2564 6.24365V8.45291C11.2564 8.72905 11.0325 8.95291 10.7564 8.95291C10.4802 8.95291 10.2564 8.72905 10.2564 8.45291V6.74365H7.94867V9.17991C7.94867 9.45605 7.72481 9.67991 7.44867 9.67991C7.17253 9.67991 6.94867 9.45605 6.94867 9.17991V6.24365Z" fill="#2F363D" />
        </svg>
    );

    const iconFPT = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.42615 12.6852V6.01855H2.42615V12.6852C2.42615 12.9536 2.52766 13.2061 2.70087 13.3884C2.87325 13.5699 3.10141 13.6667 3.33355 13.6667H13.1854C13.4175 13.6667 13.6457 13.5699 13.8181 13.3884C13.9913 13.2061 14.0928 12.9536 14.0928 12.6852V6.01855H15.0928V12.6852C15.0928 13.2027 14.8978 13.7038 14.5431 14.0772C14.1876 14.4514 13.6998 14.6667 13.1854 14.6667H3.33355C2.81916 14.6667 2.33136 14.4514 1.97587 14.0772C1.6212 13.7038 1.42615 13.2027 1.42615 12.6852Z" fill="#2F363D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.0806 8.33026C6.4361 7.95606 6.9239 7.74072 7.43829 7.74072H8.8457C9.36009 7.74072 9.84789 7.95606 10.2034 8.33026C10.5581 8.7036 10.7531 9.20472 10.7531 9.7222V14.1666H9.75311V9.7222C9.75311 9.45386 9.6516 9.20134 9.47839 9.01901C9.306 8.83756 9.07784 8.74072 8.8457 8.74072H7.43829C7.20615 8.74072 6.97799 8.83756 6.8056 9.01901C6.63239 9.20134 6.53088 9.45386 6.53088 9.7222V14.1666H5.53088V9.7222C5.53088 9.20472 5.72593 8.7036 6.0806 8.33026Z" fill="#2F363D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1813 0.333374L13.5702 0.333374C13.5701 0.333374 13.5702 0.333374 13.5702 0.333374C13.7771 0.333352 13.9756 0.40445 14.1354 0.53127C14.2947 0.657737 14.4065 0.831868 14.4588 1.02449L15.65 5.413C15.6852 5.53693 15.6946 5.66686 15.6778 5.79438C15.6608 5.92365 15.617 6.04877 15.5483 6.16092C15.4795 6.27314 15.3871 6.37035 15.2758 6.44429C15.1645 6.51831 15.0375 6.56689 14.9037 6.5851L14.9004 6.58555C14.5906 6.62564 14.1585 6.66827 13.7057 6.66994C13.2599 6.6716 12.7588 6.63405 12.3274 6.49418C11.8422 6.33704 11.258 5.98615 10.9861 5.81515L10.9838 5.81368C10.8599 5.73485 10.7582 5.62776 10.685 5.50315C10.6121 5.37888 10.5691 5.23983 10.5582 5.09738C10.5581 5.09708 10.5581 5.09678 10.5581 5.09649L10.1813 0.333374ZM11.2635 1.33337L11.5528 4.99012C11.8344 5.16547 12.2976 5.43343 12.6357 5.54287C12.9193 5.63484 13.298 5.67145 13.702 5.66995C14.0518 5.66866 14.3946 5.63918 14.6664 5.60691L13.5065 1.33337H11.2635Z" fill="#2F363D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33453 0.333374H11.1844L11.5514 4.96842C11.5514 4.96892 11.5515 4.96942 11.5515 4.96992C11.5655 5.13836 11.5343 5.30852 11.4602 5.46159C11.3857 5.61527 11.2698 5.74748 11.1225 5.84019L11.1206 5.84142C10.8384 6.01731 10.2853 6.34363 9.82113 6.49417C9.26367 6.67497 8.76871 6.77412 8.25947 6.77412C7.75024 6.77412 7.25527 6.67497 6.69782 6.49417C6.23375 6.34367 5.68059 6.01731 5.39916 5.84266L5.39646 5.84099C5.24895 5.74815 5.13288 5.61567 5.05841 5.46169C4.9843 5.30846 4.95332 5.13815 4.96743 4.96961L5.33453 0.333374ZM6.25848 1.33337L5.96674 5.01784C6.25283 5.19344 6.68638 5.43919 7.00631 5.54295C7.49948 5.70289 7.88661 5.77412 8.25947 5.77412C8.63233 5.77412 9.01946 5.70289 9.51263 5.54295C9.83267 5.43915 10.2668 5.19308 10.5521 5.01723L10.2605 1.33337H6.25848Z" fill="#2F363D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.94873 0.333374C2.94869 0.333374 2.94877 0.333374 2.94873 0.333374L6.33762 0.333374L5.96076 5.09704C5.94995 5.23957 5.90709 5.37875 5.83425 5.50314C5.76111 5.62804 5.65924 5.73538 5.5352 5.81437L5.53247 5.81611C5.26126 5.98637 4.67631 6.33721 4.19125 6.49425C3.76015 6.6336 3.25882 6.67123 2.81297 6.66957C2.36007 6.6679 1.92784 6.62526 1.6178 6.58471L1.61469 6.58429C1.48121 6.56598 1.3546 6.51747 1.24356 6.44366C1.13261 6.36992 1.04031 6.27304 0.971604 6.1612C0.902944 6.04942 0.859059 5.92471 0.841761 5.79582C0.824654 5.66835 0.833755 5.53841 0.868719 5.41442L2.06003 1.02464C2.11237 0.832026 2.22417 0.657739 2.38353 0.53127C2.54334 0.404449 2.74177 0.333352 2.94873 0.333374ZM3.01241 1.33337L1.8528 5.60634C2.12404 5.63881 2.4667 5.66829 2.81667 5.66958C3.22071 5.67108 3.59957 5.63456 3.88343 5.5428M3.01241 1.33337H5.25538L4.96603 4.99086C4.68502 5.16556 4.22143 5.43333 3.88343 5.5428" fill="#2F363D" />
        </svg>
    );
    return (
        <div className={classes.wrapper}>
            <div className={classes.icon}>
                {getDeliveryType() ? iconGPT() : iconFPT()}
            </div>
            <div className={classes.content}> {getDeliveryType() ? 'Sent by a delivery partner' : 'Local florist delivery'}</div>
        </div>
    );
};

DeliveryType.propTypes = {
    productDeliveryType: string.isRequired,
};

export default DeliveryType;
