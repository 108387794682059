/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useContext, useEffect } from 'react';
import { useUIDSeed } from 'react-uid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import {
    bool, arrayOf, number, shape, string, func, object,
} from 'prop-types';
import { CrossMerch } from '../../CrossMerchContainer/CrossMerchContainer';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        alignSelf: 'center',
    },
    dialogTitleText: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        fontWeight: 'bold',
        [theme.breakpoints.down(400)]: {
            fontSize: '14px',
        },
    },
    solidColorDivider: {
        width: '100%',
        height: '5px',
        background: theme.palette.cms?.primary || theme.palette.colorPrimary,
    },

    mainSectionContainer: {
        padding: '15px',
    },
    buttonContainer: {
        justifyContent: 'space-evenly',
        paddingTop: '25px',
        paddingBottom: '15px',
    },
    continueButton: {
        color: theme.palette.cms?.white || '#ffffff',
        background: theme.palette.primaryButton,
        width: '100%',
        '&:hover': {
            backgroundColor: theme.palette.primaryButton,
        },
    },
    continueButtonDisable: {
        color: theme.palette.cms?.white || '#ffffff',
        backgroundColor: theme.palette.cms?.gray || '#ccc',
        width: '100%',
        '&:hover': {
            backgroundColor: theme.palette.cms?.gray || '#ccc',
        },
    },
    cancelButton: {
        color: theme.palette.cms?.primary || theme.palette.colorPrimary,
        width: '100%',
        border: `1px solid ${theme.palette.cms?.primary || theme.palette.colorPrimary}`,
    },
    cancelButtonContainer: {
        width: '45%',
    },
    containueButtonContainer: {
        width: '45%',
    },
    inputField: {
        width: '100%',
    },
    closeIcon: {
        position: 'absolute',
        right: '15px',
        cursor: 'pointer',
        [theme.breakpoints.down(400)]: {
            right: '5px',
            top: '12px',
        },
    },
}));

const PersonalizationModal = ({
    isOpen, personalization: { lines, indicator }, setPersonalizationState, personalizationState, handlePersonalizationTextFieldChange, handlePersonalizationModalSubmit, closePersonalizationModal, content,
}) => {
    const classes = useStyles();
    const seed = useUIDSeed();
    const { featureFlags = {} } = useContext(CrossMerch);
    useEffect(() => {
        // This essentially mimics componentDidMount and component did update
        // It runs whenever isOpen is switched to true.
        if (isOpen === true) {
            const lineStateArray = (lines || []).map((line) => ({
                lineText: '',
                maxChars: line.maxChars,
            }));
            const personalizationStateCopy = {
                ...personalizationState, modalOpen: true, lineState: lineStateArray, indicator,
            };

            setPersonalizationState(personalizationStateCopy);
        }
    }, [isOpen]);
    const replacePlaceholders = (inputText, totalChars, totalLines, maxChars) => {
        let modifiedText = inputText;

        // Replace {{totalChars}} with totalChars
        modifiedText = modifiedText.replace(/{{totalChars}}/g, totalChars?.toString());

        // Replace {{totalLines}} with totalLines
        modifiedText = modifiedText.replace(/{{totalLines}}/g, totalLines?.toString());

        // Replace {{maxChars}} with maxChars
        modifiedText = modifiedText.replace(/{{maxChars}}/g, maxChars?.toString());

        return modifiedText;
    };

    const characterCounts = personalizationState?.lineState?.map((item) => item?.lineText?.length);
    const totalCharacters = characterCounts?.reduce((total, count) => total + count, 0);
    const maxCharsSum = lines?.map((item) => item?.maxChars).reduce((total, maxChars) => total + maxChars, 0);
    const totalChars = featureFlags['is-personalization-char-calculation-enabled'] ? totalCharacters :  lines?.[0]?.maxChars;
    const personalizationHeader = content?.entries?.[0]?.personalization?.message_heading || 'Type your personalized message or name in the fields below.';
    const modifiedPersonalizationHeader = replacePlaceholders(personalizationHeader, totalChars, lines?.length, maxCharsSum);

    const continueButtonDisabled = (indicator === '3' && !personalizationState?.lineState?.some((line) => line.lineText.length > 0));

    // Regex to remove emoji from Personalized input text
    const emojiRegex = /([\p{Extended_Pictographic}])/gu;
    const handleTextChange = (event) => {
        const { name = '', value = '' } = event?.target || {};
        const sanitizedValue = value?.replace(emojiRegex, '').replace(/\s{2,}/g, ' '); // Remove emojis from the input value
        handlePersonalizationTextFieldChange({ target: { name, value: sanitizedValue } }); // Passing the sanitized value to the handler
    };

    return (
        <Dialog open={isOpen}>
            <DialogTitle className={classes.dialogTitle}>
                <Grid container>
                    <Grid item>
                        <Typography className={classes.dialogTitleText}>
                            PERSONALIZE YOUR GIFT
                        </Typography>
                    </Grid>
                    <Grid item>
                        <CloseIcon className={classes.closeIcon} onClick={closePersonalizationModal} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <div className={classes.solidColorDivider} />
            <Grid container direction="column" className={classes.mainSectionContainer}>
                <Grid item>
                    <Typography>
                        {modifiedPersonalizationHeader}
                    </Typography>
                </Grid>
                {(lines || []).map((line, i) => (
                    <Grid item key={seed(line)}>
                        <TextField
                            label={`Line ${line.lineNumber}:`}
                            className={classes.inputField}
                            value={personalizationState?.lineState?.[i]?.lineText}
                            onChange={handleTextChange}
                            name={`${i}`}
                        />
                    </Grid>
                ))}
                <Grid item container direction="row" className={classes.buttonContainer}>
                    <Grid item className={classes.cancelButtonContainer}>
                        <Button variant="outlined" className={classes.cancelButton} onClick={closePersonalizationModal}>
                            CANCEL
                        </Button>
                    </Grid>
                    <Grid item className={classes.containueButtonContainer}>
                        <Button
                            className={`${continueButtonDisabled ? classes.continueButtonDisable : classes.continueButton}`}
                            onClick={handlePersonalizationModalSubmit}
                            disabled={continueButtonDisabled}
                            color="primary"
                        >
                            CONTINUE
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

PersonalizationModal.propTypes = {
    isOpen: bool.isRequired,
    personalization: shape({
        indicator: string.isRequired,
        lines: arrayOf(
            shape({
                lineNumber: number.isRequired,
                maxChars: number.isRequired,
            }).isRequired,
        ).isRequired,
    }).isRequired,
    setPersonalizationState: func.isRequired,
    handlePersonalizationModalSubmit: func.isRequired,
    handlePersonalizationTextFieldChange: func.isRequired,
    closePersonalizationModal: func.isRequired,
    personalizationState: shape({
        indicator: string,
        lineState: arrayOf(
            shape({
                lineNumber: number.isRequired,
                maxChars: number.isRequired,
            }).isRequired,
        ),
        modalOpen: bool.isRequired,
    }).isRequired,
    content: object.isRequired,
};

export default PersonalizationModal;
